import $ from 'jquery'
import 'jquery-migrate'
import 'jquery.stellar/jquery.stellar'

const initializeStellar = function () {
  $(window).stellar({
    horizontalScrolling: false,
    verticalScrolling: true,
    scrollProperty: 'scroll',
    positionProperty: 'transform',
    hideDistantElements: false,
    responsive: true,
  })

  const refreshStellarParallaxEffect = function () {
    if ($(window).stellar) {
      $(window).stellar('refresh')
      return $(window).trigger('resize').trigger('scroll')
    }
  }
  return $(document).on('simplero:section:load', refreshStellarParallaxEffect)
}

$(document).on('simplero:section:load', initializeStellar)
$(initializeStellar)
